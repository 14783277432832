import React, { useState } from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import petPortraitsStyle from "./pet-portraits.module.css";
import Img from "gatsby-image";
import addToMailchimp from "gatsby-plugin-mailchimp";
import env from "../config/env";
import axios from "axios";
import photosStyle from "./photos.module.css";
import santaPhotosStyle from "./socially-distant-santa-photos.module.css";

let stripe;
if (typeof window !== "undefined") {
  stripe = window.Stripe(env.GATSBY_STRIPE_PUBLIC_KEY);
}

const PetPortraitsPage = ({data}) => {
  
  const [formState, setFormValues] = useState({
    portraitType: "Classic",
    classicBackdropStyle: "Midnight",
    petName: "",
    deliveryType: "",
    frameColor: "",
    name: "",
    email: "",
    discountCode: "",
    photoB64: "",
    photoName: "",
    noOfCopies: 1,
    path: "/pet-portraits",
  });

  const [finalPrice, setFinalPrice] = useState(0);
  const [currentSku, setCurrentSku] = useState('');
  const [showPortretTypeErrorMessage, setShowPortretTypeErrorMessage] = useState({ show: false, message: "" });
  const [showDeliveryTypeErrorMessage, setShowDeliveryTypeErrorMessage] = useState({ show: false, message: "" });
  const [showNameErrorMessage, setShowNameErrorMessage] = useState({ show: false, message: "" });
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({ show: false, message: "" });
  const [showPhotoErrorMessage, setShowPhotoErrorMessage] = useState({ show: false, message: "" });

  const skus = {
    digitalDelivery: 'sku_IVPU8oXMXVCbKb',
    digitalDeliveryAndPrint: 'sku_IVPZFOxF9PLDLL',
    digitalDeliveryAndPrint10Off: 'sku_IYGIOsF75dULPm',
    digitalDeliveryAndPrint20Off: 'sku_IYGJGP1StFd3Ep',
    digitalDeliveryAndPrintAndFrame: 'sku_IYGAD9gHir5N3l',
    digitalDeliveryAndPrintAndFrame10Off: 'sku_IYGKyBjcIDtfT4',
    digitalDeliveryAndPrintAndFrame20Off: 'sku_IYGLh2FQv9CPV7',
  };

  //TEST Skus
  // const skus = {
  //   digitalDelivery: 'sku_IY2wKv4Z4n6JX8',
  //   digitalDeliveryAndPrint: 'sku_IY2zXmQipR1PX1',
  //   digitalDeliveryAndPrint10Off: 'sku_IY39ejgpU9PEQV',
  //   digitalDeliveryAndPrint20Off: 'sku_IY38Hsc6fRZeww',
  //   digitalDeliveryAndPrintAndFrame: 'sku_IY33UeLZcy8Ygp',
  //   digitalDeliveryAndPrintAndFrame10Off: 'sku_IY3BQbmohQO2hJ',
  //   digitalDeliveryAndPrintAndFrame20Off: 'sku_IY3CuWp3T3rR1E',
  // };

  const defaultStyles = {
    classicBackdropStyle: "",
  };

  const defaultPrices = {
    digitalDelivery: 20,
    digitalDeliveryAndPrint: 40,
    digitalDeliveryAndPrintAndFrame: 55,
  };

  const updateCurrentSku = (deliveryType, noOfCopies) => {
    noOfCopies = parseInt(noOfCopies);
    let sku = '';
    if (deliveryType === 'Digital Delivery Only') {
      sku = skus.digitalDelivery;
    } else if (deliveryType === 'Digital Delivery and Print and Ship a 5x7 and 8x10') {
      if (noOfCopies === 1) {
        sku = skus.digitalDeliveryAndPrint;
      } else if (noOfCopies === 2) {
        sku = skus.digitalDeliveryAndPrint10Off;
      } else {
        sku = skus.digitalDeliveryAndPrint20Off;
      }
    } else if (deliveryType === 'Digital Delivery and Print, Frame and Ship Finished Art') {
      if (noOfCopies === 1) {
        sku = skus.digitalDeliveryAndPrintAndFrame;
      } else if (noOfCopies === 2) {
        sku = skus.digitalDeliveryAndPrintAndFrame10Off;
      } else {
        sku = skus.digitalDeliveryAndPrintAndFrame20Off;
      }
    }
    setCurrentSku(sku);
  };

  const updateFinalPrice = (data) => {
    const deliveryType = data['deliveryType'] || formState.deliveryType;
    const noOfCopies = data['noOfCopies'] || formState.noOfCopies;
    let price = 0;
    let discountPercent = 0;
    let shouldCheckForDiscount = false;

    if (deliveryType === 'Digital Delivery Only') {
      price = defaultPrices.digitalDelivery;
    } else if (deliveryType === 'Digital Delivery and Print and Ship a 5x7 and 8x10') {
      price = defaultPrices.digitalDeliveryAndPrint;
      shouldCheckForDiscount = true;
    } else if (deliveryType === 'Digital Delivery and Print, Frame and Ship Finished Art') {
      price = defaultPrices.digitalDeliveryAndPrintAndFrame;
      shouldCheckForDiscount = true;
    }

    if (shouldCheckForDiscount) {
      discountPercent = noOfCopies === 2 ? 10 : (noOfCopies >= 3 ? 20 : 0);
    }

    price *= noOfCopies;
    const finalPrice = price - price * discountPercent / 100;
    setFinalPrice(finalPrice);

    updateCurrentSku(deliveryType, noOfCopies);
  };

  const handleFile = (formChangeEvent) => {
    const file = formChangeEvent.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      setFormValues({
        ...formState,
        photoName: file.name,
        photoB64: btoa(readerEvent.target.result),
      });
    };
    reader.readAsBinaryString(file);
  };

  const handleRegisterForPhotosFormChange = e => {
    if (e.target.name === "photo") {
      handleFile(e);
    } else {
      let value = e.target.value;
      if (e.target.name === "noOfCopies") {
        value = parseInt(e.target.value);
      }
      setFormValues({
        ...formState,
        [e.target.name]: value,
      });
    }

    if (e.target.name === "deliveryType" || e.target.name === "noOfCopies") {
      updateFinalPrice({[e.target.name]: e.target.value});
    }
  };

  const chooseClassic = () => {
    setFormValues({...formState, ...defaultStyles, portraitType: "Classic"});
  };

  const chooseDigitalDeliveryOnly = (e) => {
    setFormValues({...formState, noOfCopies: 1, deliveryType: e.target.value});
    updateFinalPrice({deliveryType: e.target.value, noOfCopies: 1});
  };

  const validateForm = () => {
    let isValid = true;

    if (!formState.portraitType) {
      isValid = false;
      setShowPortretTypeErrorMessage({
        show: true,
        message: "Please choose a portrait!"
      })
    }
    if (!formState.deliveryType) {
      isValid = false;
      setShowDeliveryTypeErrorMessage({
        show: true,
        message: "Please choose delivery type!"
      })
    }
    if (!formState.name) {
      isValid = false;
      setShowNameErrorMessage({
        show: true,
        message: "Please enter your name!"
      })
    }
    if (!formState.email) {
      isValid = false;
      setShowEmailErrorMessage({
        show: true,
        message: "Please enter your email!"
      })
    }
    if (!formState.photoB64) {
      isValid = false;
      setShowPhotoErrorMessage({
        show: true,
        message: "Please upload a photo!"
      })
    }

    return isValid
  };

  const saveToMailchimp = async () => {
    console.log(formState)
    try {
      await addToMailchimp(formState.email, {
          PORTRAIT: formState.portraitType,
          CLASSIC: formState.classicBackdropStyle,
          PETNAME: formState.petName,
          DLVRTYPE: formState.deliveryType,
          FRAMECOLOR: formState.frameColor,
          NAME: formState.name,
        }, env.submitPetPhotosUrl
      );

    } catch (err) {
      console.error(err);
    }
    return true;
  };

  const savePhotoToS3 = async () => {
    return axios({
      method: 'post',
      url: env.uploadPhotoUrl,
      data: {
        email: formState.email,
        base64Photo: formState.photoB64,
      },
      headers: {
        'Access-Control-Allow-Origin': window.location.origin,
      },
    });
  };

  const redirectToStripe = async () => {
    const shipping = formState.deliveryType === "Digital Delivery Only" ? undefined : { allowedCountries: ["US"] };
    return await stripe.redirectToCheckout({
      items: [{
        sku: currentSku,
        quantity: formState.noOfCopies,
      }],
      customerEmail: formState.email,
      successUrl: env.STRIPE_PET_PHOTOS_PORTRAIT_SUCCESS_URL,
      cancelUrl: window ? window.location.protocol + "//" + window.location.host + formState.path : "",
      billingAddressCollection: "required",
      shippingAddressCollection: shipping,
    });
  };

  const validateAndSaveData = async () => {
    if (validateForm()) {
      await saveToMailchimp();
      await savePhotoToS3();
      await redirectToStripe();
    }
  };

  return (
    <Layout>
      <Container>
        <div>
          <div className="title">
            {/*<h1 className={globalStyle.entryTitle}>Make Home Feel Like Home.*/}
            {/*  <p className={globalStyle.boldStyle}>Pets are Part of the Family.</p>*/}
            {/*  <p className={globalStyle.boldStyle}>Help the Whole*/}
            {/*    Family Feel the Love and Make Home Feel More Like Photo.</p>*/}
            {/*</h1>*/}
            <h1 className={globalStyle.entryTitle} >Order Your Pets Professional Portrait Today!</h1>
          </div>
        </div>

        <Row className={petPortraitsStyle.processContent}>
          <Col>
            <Img fluid={data.petPortraitProcess.childImageSharp.fluid}/>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form
              name="getYourPortrait">
              <Form.Row className={petPortraitsStyle.rowStyle}>
                <Col className={petPortraitsStyle.imagesColumn} xs={12} md={4} sm={4}>
                  <Img fluid={data.petPhoto.childImageSharp.fluid}/>
                </Col>

                <Col className={petPortraitsStyle.imagesColumn} xs={12} md={4} sm={4}>
                  <Img fluid={data.petBackdropPhoto.childImageSharp.fluid}/>
                </Col>

                <Col className={petPortraitsStyle.imagesColumn} xs={12} md={4} sm={4}>
                  <Img fluid={data.petFramePhoto.childImageSharp.fluid}/>
                </Col>
              </Form.Row >

              <Form.Row xs={12} md={12} sm={12}>
                <Col>
                  <Form.Check className={petPortraitsStyle.styleRadiobox} >
                    <Form.Check.Label htmlFor="classic">
                      <Form.Check.Input type='radio' id="classic" name="portraitType"
                                        checked={formState.portraitType === "Classic"}
                                        onChange={chooseClassic}
                      />
                      CLASSIC PORTRAIT</Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col xs={12} md={12} sm={12}>
                  {showPortretTypeErrorMessage.show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowPortretTypeErrorMessage({ show: false })}
                      dismissible
                    >
                      {showPortretTypeErrorMessage.message}
                    </Alert>
                  )}
                </Col>
              </Form.Row>

              { formState.portraitType === "Classic" &&
              <Row>
                <h3 className={petPortraitsStyle.sectionTitle}>Your Backdrop Color</h3>
              </Row>
              }
              { formState.portraitType === "Classic" &&
              <div className={[petPortraitsStyle.nextRowIcon, petPortraitsStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>
              }

              <Form.Row className={[petPortraitsStyle.rowStyle, petPortraitsStyle.backdropChoicesRow].join(" ")}>
                <Col xs={12} md={4} sm={4}></Col>
                <Col xs={12} md={4} sm={4}>
                  <Form.Check className={[petPortraitsStyle.backdropRadiobox, petPortraitsStyle.styleRadiobox]}>
                    <Form.Check.Input type='radio' id="midnight" name="classicBackdropStyle" value="Midnight"
                                      checked={formState.classicBackdropStyle === "Midnight"}
                                      onChange={handleRegisterForPhotosFormChange}/>
                    <Form.Check.Label htmlFor="midnight">Midnight</Form.Check.Label>
                    <div className={[petPortraitsStyle.colorSample, petPortraitsStyle.midnightColor].join(" ")}></div>
                  </Form.Check>
                  <Col xs={12} md={4} sm={4}></Col>
                </Col>
              </Form.Row>

              <Row>
                <h3 className={photosStyle.sectionTitle}>Choose Your Product</h3>
              </Row>

              <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>

              <Form.Row className={photosStyle.rowStyle}>
                <Col xs={12} md={4} sm={4}>
                  <div className={[photosStyle.deliveryIcon, "text-center"].join(" ")}>
                    <i className={[photosStyle.iconSize, "fa fa-envelope"].join(" ")} aria-hidden="true"></i>
                  </div>

                  <Form.Check >
                    <Form.Check.Input type='radio' id="digital-delivery-only"
                                      name="deliveryType"
                                      value="Digital Delivery Only"
                                      checked={formState.deliveryType === "Digital Delivery Only"}
                                      onClick={() => setShowDeliveryTypeErrorMessage({ show: false })}
                                      onChange={chooseDigitalDeliveryOnly}/>
                    <Form.Check.Label htmlFor="digital-delivery-only">Digital Delivery Only - <span className={photosStyle.price}>${defaultPrices.digitalDelivery}</span></Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col xs={12} md={4} sm={4}>
                  <div className={[photosStyle.deliveryIcon, "text-center"].join(" ")}>
                    <i className={[photosStyle.iconSize, "fa fa-file-image"].join(" ")} aria-hidden="true"></i>
                  </div>

                  <Form.Check>
                    <Form.Check.Input type='radio' id="digital-delivery-and-print-and-ship"
                                      name="deliveryType"
                                      value="Digital Delivery and Print and Ship a 5x7 and 8x10"
                                      checked={formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10"}
                                      onClick={() => setShowDeliveryTypeErrorMessage({ show: false })}
                                      onChange={handleRegisterForPhotosFormChange}/>
                    <Form.Check.Label htmlFor="digital-delivery-and-print-and-ship">
                      Digital Delivery and Print and Ship a 5x7 and 8x10 - <span className={photosStyle.price}>${defaultPrices.digitalDeliveryAndPrint}</span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
                <Col xs={12} md={4} sm={4}>
                  <div className={[photosStyle.deliveryIcon, "text-center"].join(" ")}>
                    <i className={[photosStyle.iconSize, "fa fa-file-image"].join(" ")} aria-hidden="true"></i>

                    <i className={[photosStyle.plusIcon, "fa fa-plus"].join(" ")}></i>

                    <div className={photosStyle.frameIcon}></div>
                  </div>

                  <Form.Check>
                    <Form.Check.Input type='radio' id="digital-delivery-and-print-frame-and-ship-art"
                                      name="deliveryType"
                                      value="Digital Delivery and Print, Frame and Ship Finished Art"
                                      checked={formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art"}
                                      onClick={() => setShowDeliveryTypeErrorMessage({ show: false })}
                                      onChange={handleRegisterForPhotosFormChange}/>
                    <Form.Check.Label htmlFor="digital-delivery-and-print-frame-and-ship-art">
                      Digital Delivery and Print, Frame and Ship Finished Art - <span className={photosStyle.price}>${defaultPrices.digitalDeliveryAndPrintAndFrame}</span>
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col xs={12} md={12} sm={12}>
                  {showDeliveryTypeErrorMessage.show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowDeliveryTypeErrorMessage({ show: false })}
                      dismissible
                    >
                      {showDeliveryTypeErrorMessage.message}
                    </Alert>
                  )}
                </Col>
              </Form.Row>

              {
                (formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10" ||
                  formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art"
                ) &&
                <Row>
                  <Col>
                    <p>
                      <span className={globalStyle.required}></span>
                      <span className={globalStyle.required}></span>
                      <span className={globalStyle.required}></span>
                      &nbsp;
                      <span className={photosStyle.deliveryInfo}>Discount:</span>&nbsp;&nbsp;


                      <span className={photosStyle.aboutProduct}>
                       Buy 2 get 10% off, Buy 3 or more get 20% off
                      </span>
                    </p>
                  </Col>
                </Row>
              }

              {formState.deliveryType &&
              <Row>
                <Col>
                  <p>
                    <span className={globalStyle.required}></span>
                    <span className={globalStyle.required}></span>
                    <span className={globalStyle.required}></span>
                    &nbsp;
                    <span className={photosStyle.deliveryInfo}>Delivery:</span>&nbsp;&nbsp;
                    {
                      formState.deliveryType === "Digital Delivery Only" &&
                      <span className={photosStyle.aboutProduct}>
                      Emailed to you in 24 to 48 Hours or Money Back.</span>
                    }

                    {
                      formState.deliveryType === "Digital Delivery and Print and Ship a 5x7 and 8x10" &&
                      <span className={photosStyle.aboutProduct}>
                      Emailed to you in 24 to 48 Hours or Money Back. Prints Arrive 5-7 Days from Digital Delivery Date.
                    </span>
                    }

                    {
                      formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
                      <span className={photosStyle.aboutProduct}>
                      Emailed to you in 24 to 48 Hours or Money Back. Framed Portrait Received 5-7 Days from Digital
                      Delivery Date.</span>
                    }
                  </p>
                </Col>
              </Row>
              }

              { formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
              <Row>
                <h3 className={photosStyle.sectionTitle}>Choose Frame Color</h3>
              </Row>
              }

              { formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
              <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>
              }

              { formState.deliveryType === "Digital Delivery and Print, Frame and Ship Finished Art" &&
              <Form.Row className={photosStyle.rowStyle}>
                <Col xs={12} md={3} sm={3}>
                  <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                    <Form.Check.Input type='radio' id="black" name="frameColor" value="Black"
                                      onChange={handleRegisterForPhotosFormChange}/>
                    <Form.Check.Label htmlFor="black">Black</Form.Check.Label>
                    <div className={[photosStyle.colorSample, photosStyle.midnightColor].join(" ")}></div>
                  </Form.Check>
                </Col>
                <Col xs={12} md={3} sm={3}>
                  <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                    <Form.Check.Input type='radio' id="white" name="frameColor" value="White"
                                      onChange={handleRegisterForPhotosFormChange}/>
                    <Form.Check.Label htmlFor="white">White</Form.Check.Label>
                    <div className={[photosStyle.colorSample, photosStyle.whiteColor].join(" ")}></div>
                  </Form.Check>
                </Col>
                <Col xs={12} md={3} sm={3}>
                  <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                    <Form.Check.Input type='radio' id="grey" name="frameColor" value="Grey"
                                      onChange={handleRegisterForPhotosFormChange}/>
                    <Form.Check.Label htmlFor="grey">Grey</Form.Check.Label>
                    <div className={[photosStyle.colorSample, photosStyle.greyColor].join(" ")}></div>
                  </Form.Check>
                </Col>
                <Col xs={12} md={3} sm={3}>
                  <Form.Check className={[photosStyle.backdropRadiobox, photosStyle.styleRadiobox]}>
                    <Form.Check.Input type='radio' id="blue" name="frameColor" value="Blue"
                                      onChange={handleRegisterForPhotosFormChange}/>
                    <Form.Check.Label htmlFor="blue">Blue</Form.Check.Label>
                    <div className={[photosStyle.colorSample, photosStyle.blueColor].join(" ")}></div>
                  </Form.Check>
                </Col>
              </Form.Row>
              }

              <Row>
                <h3 className={photosStyle.sectionTitle}>Fill Out The Form Below</h3>
              </Row>

              <div className={[photosStyle.nextRowIcon, photosStyle.mediumNextRowIcon].join(" ")}>
                <i className="fa fa-angle-double-down"></i>
              </div>

              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={12} md={8} sm={10}>
                  <Form.Group as={Col} controlId="petName" name="petName"
                              className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                    <Form.Control
                      className={photosStyle.formInput}
                      size="sm"
                      value={formState.petName}
                      type="text"
                      name="petName"
                      placeholder="Type pet name"
                      onChange={handleRegisterForPhotosFormChange}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={12} md={8} sm={10}>
                  <Form.Group as={Col} controlId="formName" className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                    <Form.Control
                      size="sm"
                      value={formState.name}
                      name="name"
                      type="text"
                      placeholder="Type your name"
                      onClick={() => setShowNameErrorMessage({ show: false })}
                      onChange={handleRegisterForPhotosFormChange}
                      className={photosStyle.formInput}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col xs={12} md={12} sm={12}>
                  {showNameErrorMessage.show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowNameErrorMessage({ show: false })}
                      dismissible
                    >
                      {showNameErrorMessage.message}
                    </Alert>
                  )}
                </Col>
              </Form.Row>

              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={12} md={8} sm={10}>
                  <Form.Group as={Col} controlId="formName" className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                    <Form.Control
                      size="sm"
                      value={formState.email}
                      name="email"
                      type="email"
                      placeholder="Type your email address"
                      onClick={() => setShowEmailErrorMessage({ show: false })}
                      onChange={handleRegisterForPhotosFormChange}
                      className={photosStyle.formInput}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col xs={12} md={12} sm={12}>
                  {showEmailErrorMessage.show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowEmailErrorMessage({ show: false })}
                      dismissible
                    >
                      {showEmailErrorMessage.message}
                    </Alert>
                  )}
                </Col>
              </Form.Row>

              <Row className="justify-content-md-center">
                <Col xs={12} md={8} sm={10}>
                  <h5 className={photosStyle.messageStyle}>All we need is any photo of your Baby or Toddler that shows their face, neck and upper chest and we will transform it into a fine professional portrait for you! Our goal is to deliver something you and your family absolutely love.</h5>
                </Col>
              </Row>

              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={12} md={8} sm={10}>
                  <Form.Group>
                    <Form.File
                      id="custom-file-translate-scss"
                      label={formState.photoName || "Select & Upload Photo"}
                      lang="en"
                      custom
                      accept="image/jpeg,image/jpg"
                      name="photo"
                      onClick={() => setShowPhotoErrorMessage({ show: false })}
                      onChange={handleRegisterForPhotosFormChange}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col xs={12} md={12} sm={12}>
                  {showPhotoErrorMessage.show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShowPhotoErrorMessage({ show: false })}
                      dismissible
                    >
                      {showPhotoErrorMessage.message}
                    </Alert>
                  )}
                </Col>
              </Form.Row>

              {formState.deliveryType !== 'Digital Delivery Only' &&
              <Form.Row className={[photosStyle.rowStyle, "justify-content-md-center"].join(" ")}>
                <Col xs={12} md={8} sm={10}>
                  <Row>
                    <Col xs={12} md={6} sm={6}>
                      <p>Number Of Copies:</p>
                    </Col>
                    <Col xs={12} md={2} sm={2}>
                      <Form.Group as={Col} controlId="formName"
                                  className={[santaPhotosStyle.registerFormGroup, santaPhotosStyle.formLabel]}>
                        <Form.Control
                          size="sm"
                          value={formState.noOfCopies}
                          name="noOfCopies"
                          type="number"
                          min="1"
                          onChange={handleRegisterForPhotosFormChange}
                          className={photosStyle.formInput}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Form.Row>
              }

              <Row className="justify-content-md-center">
                <Col className="text-center">
                  <Button
                    type="button"
                    className={[
                      globalStyle.btnPrimary,
                      photosStyle.goldButton,
                      photosStyle.buyNowButton
                    ]}
                    onClick={validateAndSaveData}
                  >
                    BUY NOW {finalPrice ? ' $' + finalPrice : ''}
                  </Button>
                </Col>
              </Row>



            </Form>
          </Col>
        </Row>

      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    petPortraits: file(relativePath: { eq: "pet-portraits/pet-portraits.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    petPhoto: file(relativePath: { eq: "pet-portraits/pet-photo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    petBackdropPhoto: file(relativePath: { eq: "pet-portraits/pet-backdrop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    petFramePhoto: file(relativePath: { eq: "pet-portraits/pet-frame.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    petPortraitProcess: file(relativePath: { eq: "pet-portraits/pet-portraits.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skus: allStripeSku {
      edges {
        node {
          id
          price
          attributes {
            size
          }
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export default PetPortraitsPage
